<template>
  <v-container fluid no-gutters>
    <B2cTabs/>

    <div
      class="d-flex mt-8"
      v-if="checkWritePermission($modules.b2cconfig.blogs.slug)"
    >
      <v-btn
        color=" darken-1"
        class="white--text blue-color ml-auto"
        text
        height="48"
        @click="showBlog()"
      >
        <AddIcon />
        <span class="ml-1">Add Blog</span>
      </v-btn>
    </div>
    <v-row class="mt-4">
      <template v-for="blog in blogs">
        <v-col :key="blog.id" lg="4" md="6" xl="3" sm="12">
          <v-card
            style="border-radius: 8px"
            class="shadow pointer"
            @click="showBlog(blog)"
          >
            <v-card-text>
              <simple-image
                :image="blog.banner.file_path"
                defaultImage="event"
                style="border-radius: 6px; aspect-ratio: 3/2"
              ></simple-image>
              <p
                class="font-semibold text-lg black--text line-clamp-2 mt-6 blog-title"
              >
                {{ blog.title }}
              </p>
              <div>
                <p class="text-base text-dark-gray mb-2">Excerpt</p>
                <p class="text-base text-blue line-clamp-3 excerpt">
                  {{ blog.excerpt }}
                </p>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </template>
    </v-row>
    <br />
    <v-pagination
      v-if="totalPages > 0"
      v-model="page"
      :length="totalPages"
    ></v-pagination>
  </v-container>
</template>

<script>
import SimpleImage from "@/components/Image/SimpleImage.vue";
import AddIcon from "@/assets/images/misc/plus-icon.svg";
import B2cTabs from "@/views/B2CConfigurations/B2cTabs.vue";

export default {
  components: {B2cTabs, AddIcon, SimpleImage },
  mounted() {
    this.btnShow = true;
    this.getData();
  },
  data() {
    return {
      page: 1,
      totalPages: 0,
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      blogs: [],
      btnShow: false,
    };
  },
  watch: {
    page() {
      this.getData();
    },
  },
  methods: {
    getData() {
      this.blogs = [];
      this.showLoader("Loading");
      this.$http
        .get(`venues/b2c/blogs`, {
          params: {
            page: this.page,
            per_page: 12,
          },
        })
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.blogs = response.data?.data?.data || [];
            this.page = response.data?.data?.current_page || 1;
            this.totalPages = response.data?.data?.last_page || 0;
            this.hideLoader();
          }
        })
        .catch((error) => {
          console.log(error);
          this.hideLoader();
        });
    },
    showBlog(blog = null) {
      if (blog) {
        this.$router.push({ name: "B2CBlog", params: { id: btoa(blog.id) } });
      } else {
        this.$router.push({ name: "B2CBlog" });
      }
    },
  },
};
</script>

<style scoped>
.btn_bg {
  background-color: #fff;
  border-bottom: 3px solid #066a8c;
  border-radius: 10px;
  box-shadow: 0 3px 3px -2px black;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.excerpt {
  min-height: 61px;
}
.blog-title {
  min-height: 41px;
}
</style>
